.nav-bar{
    height: 18vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    align-items: center;
    background-color: #353755;
    color: #fcc25a;
    h1{
        font-size:3.2rem;
        font-weight:900;
        height: auto;
        cursor: pointer;
    }
    h1:hover{
            text-shadow: 0px 0px 2px #e0e4ee;
    }
    ul{
        display: flex;
        width: 30%;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        font-size: 2rem;
        li{
            cursor: pointer;
        }
        li:hover{
                text-shadow: 0px 0px 1px #e0e4ee;
        }
    }
}