.favorites{
    width: 100%;
    height: 100%;
    .favorites-cards{
        height: 100%;
        width:75%;
        margin: 0 auto;
        .row{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-content: space-around;
            .column.brewery{
                width:30%;
            }
        }
    }
}
.no-favorites{
    width: 100%;
    height: 82vh;
    display: flex;
    justify-content: center;
    margin: auto 0;
    align-items: center;
    align-content: center;
    .no-favorites-message{
        width:75%;
        text-align: center;
        line-height: 2;
        font-size: 3rem;
    }
}